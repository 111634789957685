<template>
  <v-dialog v-model="dialog" fullscreen>
    <v-card>
      <v-card-text>
        <SlowMovingStock />
      </v-card-text>
      <v-card-actions>
        <v-btn @click="dialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SlowMovingStock from './SlowMovingStock.vue';

export default {
  name: 'SlowMovingStockDialog',
  components: {
    SlowMovingStock,
  },
  data() {
    return {
      dialog: false,
    };
  },
  mounted() {
    this.dialog = true;
  },
};
</script>
