<template>
  <div v-if="stockItems.length > 0" fluid class="pa-5">
    <h2>We see you haven't taken these products:</h2>
    <v-row class="d-flex mt-5 pa-1">
      <v-col cols="12" xs="12" sm="6" md="4" lg="3" xl="2"
        class="d-flex align-stretch col-sm-padding stock-item-card"
        v-for="(stockItem, index) in stockItems"
        :data-cy="`stock-item-card-${index}`"
        :key="`stockItem-${index}`"
      >
        <StockItemCard :stockItem="stockItem" @add="add" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import StockItemCard from './StockItemCard.vue';
import stockItem from '../mixins/stockItem';
import cartMethods from '../mixins/cartMethods';

export default {
  name: 'SlowMovingStock',
  components: {
    StockItemCard,
  },
  mixins: [cartMethods, stockItem],
  data() {
    return {
      stockItems: [],
    };
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    ...mapActions('stockItems', ['LOAD_slowMovingStockItems']),
    async refresh() {
      this.stockItems = await this.LOAD_slowMovingStockItems();
    },
  },
};
</script>
