/* eslint-disable max-len */
import { useAuth } from '@/use';
import { odata } from '../../conf';
import Actions from '../../odata/actions';

const actions = new Actions('stockItem', 'stockItems');

const customActions = {
  async LOAD_stockItemsMin({ commit, rootGetters }) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('stockItems')
      .query({
        $filter: 'statusId eq 1',
        $select: 'itemCode, description',
      });
    commit('SET_stockItems', data);
    return data;
  },
  async LOAD_stockItemsWithNoCategory({ rootGetters }) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('stockItems')
      .query({
        $filter: 'webCategory1Id eq null AND statusId eq 1',
      });
    return data;
  },
  async LOAD_stockLimits({ commit, rootGetters }) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('stockLimits')
      .query();
    commit('SET_stockLimits', data);
    return data;
  },
  async LOAD_stockItemsWithNoPhoto({ rootGetters }) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('stockItems')
      .query({
        $filter: 'statusId eq 1',
      });
    return data;
  },
  async LOAD_slowMovingStockItems({ rootGetters }) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get('stockItems/SlowMovingStock')
      .query();
    return data;
  },
  async LOAD_stockItemsByWebCategory1({ rootGetters }, categoryId) {
    const token = await useAuth.getAccessToken();
    // if (rootGetters['app/isOffline']) {
    //   const localData = await this.$db.getAll('stockItems');
    //   return localData.filter((x) => x.webCategory1Id?.toString() === categoryId?.toString());
    // }
    const data = await odata.o(rootGetters, token)
      .get('stockItems')
      .query({
        $filter: `webCategory1Id eq ${categoryId}`,
        $select: 'itemCode, webCategory1Id, webCategory2Id, webCategory3Id, webCategory4Id',
      });

    return data;
  },
  async LOAD_stockItemsByWebCategory2({ rootGetters }, categoryId) {
    const token = await useAuth.getAccessToken();
    // if (rootGetters['app/isOffline']) {
    //   const localData = await this.$db.getAll('stockItems');
    //   return localData.filter((x) => x.webCategory2Id?.toString() === categoryId?.toString());
    // }
    const data = await odata.o(rootGetters, token)
      .get('stockItems')
      .query({
        $filter: `webCategory2Id eq ${categoryId}`,
      });

    return data;
  },
  async LOAD_stockItemsByWebCategory3({ rootGetters }, categoryId) {
    const token = await useAuth.getAccessToken();
    // if (rootGetters['app/isOffline']) {
    //   const localData = await this.$db.getAll('stockItems');
    //   return localData.filter((x) => x.webCategory3Id?.toString() === categoryId?.toString());
    // }
    const data = await odata.o(rootGetters, token)
      .get('stockItems')
      .query({
        $filter: `webCategory3Id eq ${categoryId}`,
      });

    return data;
  },
  async LOAD_stockItemsByWebCategory4({ rootGetters }, categoryId) {
    const token = await useAuth.getAccessToken();
    // if (rootGetters['app/isOffline']) {
    //   const localData = await this.$db.getAll('stockItems');
    //   return localData.filter((x) => x.webCategory4Id?.toString() === categoryId?.toString());
    // }
    const data = await odata.o(rootGetters, token)
      .get('stockItems')
      .query({
        $filter: `webCategory4Id eq ${categoryId}`,
      });

    return data;
  },
  async LOAD_favouriteStockItems({ commit, rootGetters }) {
    const token = await useAuth.getAccessToken();
    // if (rootGetters['app/isOffline']) {
    //   const localData = await this.$db.getAll('favourites');
    //   commit('SET_favouriteStockItems', localData);
    //   return localData;
    // }
    const data = await odata.o(rootGetters, token)
      .get('favouriteStockItems')
      .query();
    commit('SET_favouriteStockItems', data);
    return data;
  },
  async LOAD_divisionFavouriteStockItems({ commit, rootGetters }, divisionId) {
    const token = await useAuth.getAccessToken();
    const data = await odata.o(rootGetters, token)
      .get(`divisionStockItems?division=${divisionId}`)
      .query();
    commit('SET_divisionStockItems', data);
    return data;
  },
  async LOAD_stockItem({ commit, rootGetters }, id) {
    const token = await useAuth.getAccessToken();
    // if (rootGetters['app/isOffline']) {
    //   const localData = await this.$db.get('stockItems', id);
    //   commit('SET_stockItem', localData);
    // }
    const i = odata.parseId(id);
    const data = await odata.o(rootGetters, token)
      .get(`stockItems('${i}')`)
      .query();
    commit('SET_stockItem', data);
    return data;
  },
  async UPDATE_stockItem({ rootGetters }, data) {
    await odata.o(rootGetters)
      .patch(`stockItems('${data.itemCode}')`, data)
      .query();
    return data;
  },

  // async LOAD_stockData({ dispatch, rootState }) {
  //   try {
  //     await Promise.all([
  //       (async () => {
  //         await dispatch('deliveryAddresses/LOAD_deliveryAddresses', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('divisions/LOAD_divisions', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('pricings/LOAD_pricings', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('LOAD_stockItems');
  //       })(),
  //       (async () => {
  //         await dispatch('favourites/LOAD_favourites', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('LOAD_stockLimits');
  //       })(),
  //       (async () => {
  //         const { activeCustomer } = rootState.customers;
  //         if (activeCustomer) {
  //           await dispatch('softSaleStockItems/LOAD_softSaleStockItemsByCustomer', activeCustomer.customerCode, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         await dispatch('webCategories/LOAD_webCategory1', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('webCategories/LOAD_webCategory2', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('webCategories/LOAD_webCategory3', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('webCategories/LOAD_webCategory4', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('deliveryDates/LOAD_deliveryDates', null, { root: true });
  //       })(),
  //       (async () => {
  //         await dispatch('taxes/LOAD_taxes', null, { root: true });
  //       })(),
  //     ]);
  //   } catch (error) {
  //     this.$log.error(error);
  //   }
  // },

  // async LOAD_stockDataSafe({ dispatch, rootState, state }) {
  //   try {
  //     await Promise.all([
  //       (async () => {
  //         if (!rootState.deliveryDates.deliveryDates
  //             || rootState.deliveryDates.deliveryDates.length === 0) {
  //           await dispatch('deliveryDates/LOAD_deliveryDates', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.divisions.divisions.length || rootState.divisions.divisions.length === 0) {
  //           await dispatch('divisions/LOAD_divisions', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!state.favouriteStockItems.length || state.favouriteStockItems.length === 0) {
  //           if (rootState.app.divisionId) {
  //             await dispatch('LOAD_divisionFavouriteStockItems', rootState.app.divisionId);
  //           }
  //         }
  //       })(),
  //       (async () => {
  //         if (!state.favouriteStockItems.length || state.favouriteStockItems.length === 0) {
  //           await dispatch('LOAD_favouriteStockItems');
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.deliveryAddresses.deliveryAddresses.length
  //             || rootState.deliveryAddresses.deliveryAddresses.length === 0) {
  //           await dispatch('deliveryAddresses/LOAD_deliveryAddresses', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.pricings.pricings || rootState.pricings.pricings.length === 0) {
  //           await dispatch('pricings/LOAD_pricings', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!state.stockItems || state.stockItems.length === 0) {
  //           await dispatch('LOAD_stockItems');
  //         }
  //       })(),
  //       (async () => {
  //         if (!state.favouriteStockItems || state.favouriteStockItems.length === 0) {
  //           await dispatch('favourites/LOAD_favourites', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!state.stockLimits || state.stockLimits.length === 0) {
  //           await dispatch('LOAD_stockLimits');
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.softSaleStockItems.softSaleStockItems
  //             || rootState.softSaleStockItems.softSaleStockItems.length === 0) {
  //           const { activeCustomer } = rootState.customers;
  //           if (activeCustomer) {
  //             await dispatch('softSaleStockItems/LOAD_softSaleStockItemsByCustomer', activeCustomer.customerCode, { root: true });
  //           }
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.webCategories.webCategories1
  //           || rootState.webCategories.webCategories1.length === 0) {
  //           await dispatch('webCategories/LOAD_webCategory1', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.webCategories.webCategories2
  //           || rootState.webCategories.webCategories2.length === 0) {
  //           await dispatch('webCategories/LOAD_webCategory2', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.webCategories.webCategories3
  //           || rootState.webCategories.webCategories3.length === 0) {
  //           await dispatch('webCategories/LOAD_webCategory3', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.webCategories.webCategories4
  //             || rootState.webCategories.webCategories4.length === 0) {
  //           await dispatch('webCategories/LOAD_webCategory4', null, { root: true });
  //         }
  //       })(),
  //       (async () => {
  //         if (!rootState.taxes.taxes || rootState.taxes.taxes.length === 0) {
  //           await dispatch('taxes/LOAD_taxes', null, { root: true });
  //         }
  //       })(),
  //     ]);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // },
};

export default { ...actions, ...customActions };
